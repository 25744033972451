import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"

// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "../assets/scss/react-tabs.scss"
import Head from "../components/head"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"

const ProductTemplate = ({ data }) => {
  const product = data.shopifyProduct

  const formatPrice = (amount) => {
    let price = (amount / 1).toFixed(2)
    let numberFormat = new Intl.NumberFormat(["en-US"], {
      style: "currency",
      currency: "USD",
      currencyDisplay: "symbol",
    })
    return numberFormat.format(price)
  }

  let availability
  let outOfStock
  let orderButton

  if (product.variants[0].availableForSale) {
    availability = "https://schema.org/InStock"
    orderButton = (
      <form
        method="post"
        name="americartform"
        action="https://s1746.americommerce.com/store/addtocart.aspx"
      >
        <div className="product-buttons">
          <input
            value={`s-1746^${product.variants[0].sku}^${product.title}^${product.priceRangeV2.maxVariantPrice.amount}^1^8.0^^ea^${product.variants[0].weight}^`}
            type="hidden"
            name="item"
          />
          <input type="hidden" name="op1" />
          <input type="hidden" name="op2" />
          <input
            value="Add To Cart"
            className="btn btn-solid"
            type="submit"
            name="addToCartButton"
          />
        </div>
      </form>
    )
  } else {
    availability = "https://schema.org/OutOfStock"
    outOfStock = (
      <p>
        <em>
          <b style={{ color: "red" }}>
            We are sorry but this item is out of stock
          </b>
        </em>
      </p>
    )
  }

  let category1, category1Link, category2, category2Link

  if (product.productType === "Basics") {
    category1 = "Throw Your Voice"
    category1Link = "/throw-your-voice"
    category2 = "Basic Ventriloquist Dummies"
    category2Link = "/basic-ventriloquist-dummies"
  } else if (product.productType === "Standard Upgrades") {
    category1 = "Throw Your Voice"
    category1Link = "/throw-your-voice"
    category2 = "Standard Upgrade Ventriloquist Dummies"
    category2Link = "/standard-upgrade-ventriloquist-dummies"
  } else if (product.productType === "Books, DVD's, Cases & Stands") {
    category1 = "Throw Your Voice"
    category1Link = "/throw-your-voice"
    category2 = "Books, DVDs, Cases & Stands"
    category2Link = "/books-dvds-cases-and-stands"
  } else if (product.productType === "Marionettes") {
    category1 = "Throw Your Voice"
    category1Link = "/throw-your-voice"
    category2 = "Marionettes"
    category2Link = "/marionettes"
  } else if (product.productType === "Accessories & Replacement Stuff") {
    category1 = "Throw Your Voice"
    category1Link = "/throw-your-voice"
    category2 = "Accessories & Replacements Stuff"
    category2Link = "/accessories-replacement-stuff"
  } else if (product.productType === "Female Dummies") {
    category1 = "Throw Your Voice"
    category1Link = "/throw-your-voice"
    category2 = "Female Ventriloquist Dummies"
    category2Link = "/female-ventriloquist-dummies"
  } else if (product.productType === "Deluxe Upgrades") {
    category1 = "Throw Your Voice"
    category1Link = "/throw-your-voice"
    category2 = "Deluxe Upgrade Ventriloquist Dummies"
    category2Link = "/deluxe-upgrade-ventriloquist-dummies"
  } else if (product.productType === "Super Deluxe Upgrades") {
    category1 = "Throw Your Voice"
    category1Link = "/throw-your-voice"
    category2 = "Super Deluxe Upgrade Ventriloquist Dummies"
    category2Link = "/super-deluxe-upgrade-ventriloquist-dummies"
  } else if (product.productType === "Hand Puppets") {
    category1 = "Throw Your Voice"
    category1Link = "/throw-your-voice"
    category2 = "Hand Puppets"
    category2Link = "/hand-puppets"
  } else if (product.productType === "Upgrade Certificates") {
    category1 = "Throw Your Voice"
    category1Link = "/throw-your-voice"
    category2 = "Upgrade Certificates"
    category2Link = "/upgrade-certificates"
  } else if (product.productType === "Stretch Ducks") {
    category1 = "Throw Your Voice"
    category1Link = "/throw-your-voice"
    category2 = "Stretch Ducks"
    category2Link = "/stretch-ducks"
  } else if (product.productType === "Limietd Editions") {
    category1 = "Throw Your Voice"
    category1Link = "/throw-your-voice"
    category2 = "Limited Editions"
    category2Link = "/limited-editions"
  } else if (product.productType === "Bonus Bundles") {
    category1 = "Throw Your Voice"
    category1Link = "/throw-your-voice"
    category2 = "Bonus Bundles"
    category2Link = "/bonus-bundles"
  } else if (product.productType === "Gramps & Granny") {
    category1 = "Throw Your Voice"
    category1Link = "/throw-your-voice"
    category2 = "Gramps & Granny Dummies"
    category2Link = "/gramps-granny"
  } else if (product.productType === "Jeff Dunham Stuff") {
    category1 = "Throw Your Voice"
    category1Link = "/throw-your-voice"
    category2 = "Jeff Dunham Stuff"
    category2Link = "/jeff-dunham-stuff"
  } else if (product.productType === "Puppet-on-a-Stick") {
    category1 = "Throw Your Voice"
    category1Link = "/throw-your-voice"
    category2 = "Puppet-on-a-Stick"
    category2Link = "/puppet-on-a-stick"
  } else if (product.productType === "Professional Dummies") {
    category1 = "Throw Your Voice"
    category1Link = "/throw-your-voice"
    category2 = "Professional Ventriloquist Dummies"
    category2Link = "/professional-ventriloquist-dummies"
  } else if (product.productType === "ThrowThings Accessories") {
    category1 = "Things To Throw"
    category1Link = "/tt-things-to-throw"
    category2 = "ThrowThings Accessories"
    category2Link = "/throwthings-accessories"
  } else if (product.productType === "Things To Throw") {
    category1 = "Things To Throw"
    category1Link = "/tt-things-to-throw"
    category2 = "Things To Throw"
    category2Link = "/throwthings-accessories"
  } else if (product.productType === "Bocce Sets") {
    category1 = "Things To Throw"
    category1Link = "/tt-things-to-throw"
    category2 = "Bocce Sets"
    category2Link = "/bocce-sets"
  } else if (product.productType === "Baseballs, Softballs & T-Balls") {
    category1 = "Things To Throw"
    category1Link = "/tt-things-to-throw"
    category2 = "Baseballs, Softballs & T-Balls"
    category2Link = "/baseballs-softballs-t-balls"
  } else if (product.productType === "Throw Some Dice") {
    category1 = "Things To Throw"
    category1Link = "/tt-things-to-throw"
    category2 = "Throw Some Dice"
    category2Link = "/throw-some-dice"
  } else if (product.productType === "Throw A Punch") {
    category1 = "Things To Throw"
    category1Link = "/tt-things-to-throw"
    category2 = "Throw A Punch"
    category2Link = "/throw-a-punch"
  } else if (product.productType === "Footballs") {
    category1 = "Things To Throw"
    category1Link = "/tt-things-to-throw"
    category2 = "Footballs"
    category2Link = "/footballs"
  } else if (product.productType === "Darts & Dart Boards") {
    category1 = "Things To Throw"
    category1Link = "/tt-things-to-throw"
    category2 = "Darts & Dart Boards"
    category2Link = "/darts-art-boars"
  } else if (product.productType === "Boomerangs") {
    category1 = "Things To Throw"
    category1Link = "/things-to-throw"
    category2 = "Boomerangs"
    category2Link = "/boomerangs"
  } else if (product.productType === "Throw A Party") {
    category1 = "Throw A Party"
    category1Link = "/throw-a-party"
    category2 = "Throw A Party"
    category2Link = "/throw-a-party"
  } else if (product.productType === "Throw It On The Barbie") {
    category1 = "Throw It In The Barbie"
    category1Link = "/throw-it-on-the-barbie"
    category2 = "Throw It On The Barbie"
    category2Link = "/throw-it-on-the-barbie"
  } else if (product.productType === "Throw Backs") {
    category1 = "Throw Backs"
    category1Link = "/throwbacks"
    category2 = "Throw Backs"
    category2Link = "/throwbacks"
  } else if (product.productType === "Throw Up") {
    category1 = "Throw Up"
    category1Link = "/throw-up"
    category2 = "Throw Up"
    category2Link = "/throw-up"
  } else if (product.productType === "Throw Your Money Away") {
    category1 = "Throw Your Money Away"
    category1Link = "/throw-your-money-away"
    category2 = "Throw Your Money Away"
    category2Link = "/throw-your-money-away"
  } else if (product.productType === "ThrowThings Things") {
    category1 = "ThrowThings Things"
    category1Link = "/throwthings-things"
    category2 = "ThrowThings Things"
    category2Link = "/throwthings-things"
  } else if (product.productType === "Throw Things Away") {
    category1 = "Throw Things Away"
    category1Link = "/throw-things-away"
    category2 = "Throw Things Away"
    category2Link = "/throw-things-away"
  } else {
    category1 = ""
    category1Link = ""
    category2 = ""
    category2Link = ""
  }

  return (
    <Layout>
      <Head
        title={product.title}
        description={`Get a ${product.title} for ${formatPrice(`${product.priceRangeV2.maxVariantPrice.amount}`)} from the ${category1} department at ThrowThings.com`}
      />

      {/* <!-- breadcrumb start --> */}
      <div className="breadcrumb-section">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <div className="page-title">
                <Link to={category1Link}>
                  <h2>{category1}</h2>
                </Link>
              </div>
            </div>
            <div className="col-sm-6">
              <nav aria-label="breadcrumb" className="theme-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={category2Link}>{category2}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {product.title}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- breadcrumb End --> */}

      {/* <!-- section start --> */}
      <section>
        <div className="collection-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-1 col-sm-2 col-xs-12">
                <div className="row">
                  <div className="col-12 p-0">
                    {/* Add images back in at later date */}
                    <div className="slider-right-nav">
                      {/* <div><img src={require("../images/pro3/1.jpg")} alt="" className="img-fluid blur-up lazyload" /></div>
                                            <div><img src={require("../images/pro3/2.jpg")} alt="" className="img-fluid blur-up lazyload" /></div>
                                            <div><img src={require("../images/pro3/27.jpg")} alt="" className="img-fluid blur-up lazyload" /></div>
                                            <div><img src={require("../images/pro3/27.jpg")} alt="" className="img-fluid blur-up lazyload" /></div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-sm-10 col-xs-12 order-up">
                <div className="product-right-slick">
                  <div>
                    {product.images.map(image => (
                      <GatsbyImage image={image.gatsbyImageData}
                        // fluid={image.localFile.childImageSharp.gatsbyImageData}
                        key={image.id}
                        alt={product.title}
                      />
                    ))}

                    {/* <Img fluid={product.images[0].localFile.childImageSharp.fluid}
                      key={product.images[0].id}
                      alt={product.title}
                    // className="img-fluid lazyload image_zoom_cls-0"
                    /> */}

                    {/* <img
                      src={product.images[0].originalSrc}
                      alt=""
                      className="img-fluid lazyload image_zoom_cls-0"
                    /> */}
                  </div>
                  {/* <div><img src={require("../images/pro3/2.jpg")} alt="" className="img-fluid blur-up lazyload image_zoom_cls-1" /></div>
                                    <div><img src={require("../images/pro3/27.jpg")} alt="" className="img-fluid blur-up lazyload image_zoom_cls-2" /></div>
                                    <div><img src={require("../images/pro3/27.jpg")} alt="" className="img-fluid blur-up lazyload image_zoom_cls-3" /></div> */}
                </div>
              </div>
              <div className="col-lg-6 rtl-text">
                <div className="product-right">
                  <h2>{product.title}</h2>
                  {/* <h4><del>$459.00</del><span>55% off</span></h4> */}
                  <h3>{formatPrice(`${product.priceRangeV2.maxVariantPrice.amount}`)}</h3>
                  {/* <ul className="color-variant">
                                        <li className="bg-light0"></li>
                                        <li className="bg-light1"></li>
                                        <li className="bg-light2"></li>
                                    </ul> */}
                  {/* <div className="product-description border-product"> */}
                  {/* <h6 className="product-title size-text">select size <span><Link to="" data-toggle="modal" data-target="#sizemodal">size chart</Link></span></h6>
                                        <div className="modal fade" id="sizemodal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div className="modal-dialog modal-dialog-centered" role="document">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="exampleModalLabel">Sheer Straight Kurta</h5>
                                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                                    </div>
                                                    <div className="modal-body"><img src={require("../assets/images/size-chart.jpg")} alt="" className="img-fluid blur-up lazyload" /></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="size-box">
                                            <ul>
                                                <li className="active"><Link to="#">s</Link></li>
                                                <li><Link to="#">m</Link></li>
                                                <li><Link to="#">l</Link></li>
                                                <li><Link to="#">xl</Link></li>
                                            </ul>
                                        </div> */}
                  {/* Add in quantity box if desired. Input needs to be fixed and add state. See "Adding Forms" */}
                  {/*<h6 className="product-title">quantity</h6>
                                        <div className="qty-box">
                                            <div className="input-group"><span className="input-group-prepend"><button type="button" className="btn quantity-left-minus" data-type="minus" data-field=""><i className="ti-angle-left"></i></button> </span>
                                                <input type="text" name="quantity" className="form-control input-number" value="1"> <span className="input-group-prepend"><button type="button" className="btn quantity-right-plus" data-type="plus" data-field=""><i className="ti-angle-right"></i></button></span>{/*</input></div>
                                                </div>*/}
                  {/* </div> */}
                  {/* <div className="product-buttons"><Link to="#" data-toggle="modal" data-target="#addtocart" className="btn btn-solid">add to cart</Link> 
                                    <Link to="#" className="btn btn-solid">buy now</Link>
                                    </div> */}

                  {/* <div className="product-buttons"><a href="https://www.cartserver.com/sc/cart.cgi" name="americartform" method="post" className="btn btn-solid">
                                    <input value={`s-1746^${product.variants[0].sku}^${product.title}^${product.priceRangeV2.maxVariantPrice.amount}^1^8.0^^ea^${product.variants[0].weight}^`} type="hidden" name="item"/>
			                                    </a> </div> */}
                  {orderButton}
                  {/* <form
                    method="post"
                    name="americartform"
                    action="https://s1746.americommerce.com/store/addtocart.aspx"
                  >

                    <div className="product-buttons">
                      <input
                        value={`s-1746^${product.variants[0].sku}^${product.title}^${product.priceRangeV2.maxVariantPrice.amount}^1^8.0^^ea^${product.variants[0].weight}^`}
                        type="hidden"
                        name="item"
                      />
                      <input type="hidden" name="op1" />
                      <input type="hidden" name="op2" />
                      <input
                        value="Add To Cart"
                        className="btn btn-solid"
                        type="submit"
                        name="addToCartButton"
                      />
                    </div>
                  </form>*/}
                  {/* <input value="Add_To_Cart" src="https://www.throwthings.com/images/addtocart2.gif" type="image" width="323" height="85"/> */}

                  {/* <input value="s-1746^{product.variants[0].sku}^{product.title}^{product.priceRangeV2.maxVariantPrice.amount}^1^8.0^^ea^4^" type="hidden" name="item0"/> */}

                  <div className="border-product">
                    <h6 className="product-title">product details</h6>
                    {/* <p>{product.description}</p> */}
                    {/* <div>{product.descriptionHtml}</div>  */}
                    {outOfStock}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: product.descriptionHtml,
                      }}
                    />
                  </div>

                  {/* <!-- social media area --> */}
                  {/* <div className="border-product">
                                        <h6 className="product-title">share it</h6>
                                        <div className="product-icon">
                                            <ul className="product-social">
                                                <li><Link to="#"><i className="fa fa-facebook"></i></Link></li>
                                                <li><Link to="#"><i className="fa fa-google-plus"></i></Link></li>
                                                <li><Link to="#"><i className="fa fa-twitter"></i></Link></li>
                                                <li><Link to="#"><i className="fa fa-instagram"></i></Link></li>
                                                <li><Link to="#"><i className="fa fa-rss"></i></Link></li>
                                            </ul>
                                            <form className="d-inline-block">
                                                <button className="wishlist-btn"><i className="fa fa-heart"></i><span className="title-font">Add To WishList</span></button>
                                            </form>
                                        </div>
                                    </div> */}

                  {/* <!-- timer area --> */}
                  {/* <div className="border-product">
                                        <h6 className="product-title">Time Reminder</h6>
                                        <div className="timer">
                                            <p id="demo"><span>25 <span className="padding-l">:</span> <span className="timer-cal">Days</span> </span><span>22 <span className="padding-l">:</span> <span className="timer-cal">Hrs</span> </span><span>13 <span className="padding-l">:</span> <span className="timer-cal">Min</span> </span><span>57 <span className="timer-cal">Sec</span></span>
                                            </p>
                                        </div>
                                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Section ends --> */}

      {/* <!-- product-tab starts --> */}
      {/* <div className="container">
                <Tabs>
                    <TabList role="tablist">
                        <Tab id="tab1" role="tab" aria-controls="panel1">Description</Tab>
                        <Tab id="tab2" role="tab" aria-controls="panel2">Details</Tab>
                        <Tab id="tab3" role="tab" aria-controls="panel3">Video</Tab>
                             <Tab>Write Review</Tab> 
                    </TabList>
                    <TabPanel>
                        <div className="tab-pane fade show active" id="panel1" role="tabpanel" aria-labelledby="tab1">
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                        </div>

                    </TabPanel>
                    <TabPanel>
                        <div className="tab-pane fade show active" id="panel2" role="tabpanel" aria-labelledby="tab2">
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                            <div className="single-product-tables">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>Febric</td>
                                            <td>Chiffon</td>
                                        </tr>
                                        <tr>
                                            <td>Color</td>
                                            <td>Red</td>
                                        </tr>
                                        <tr>
                                            <td>Material</td>
                                            <td>Crepe printed</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>Length</td>
                                            <td>50 Inches</td>
                                        </tr>
                                        <tr>
                                            <td>Size</td>
                                            <td>S, M, L .XXL</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </TabPanel>
                    <TabPanel>
                        <div className="tab-pane fade show active" id="panel3" role="tabpanel" aria-labelledby="tab3">
                            <div className="mt-4 text-center">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/BUWzX78Ye_8" allow="autoplay; encrypted-media" allowfullscreen></iframe>
                            </div>
                        </div>
                    </TabPanel>
                </Tabs>
            </div>
 */}

      {/* <!-- related product section start --> */}
      {/* <section className="section-b-space ratio_asos">
                <div className="container">
                    <div className="row">
                        <div className="col-12 product-related">
                            <h2>related products</h2></div>
                    </div>
                    <div className="row search-product">
                        <div className="col-xl-2 col-md-4 col-sm-6">
                            <div className="product-box">
                                <div className="img-wrapper">
                                    <div className="front">
                                        <a href="#"><img src={product.images[0].originalSrc} className="img-fluid lazyload bg-img" alt="" /></a>
                                    </div>
                                    <div className="back">
                                        <a href="#"><img src={require("../assets/images/pro3/34.jpg")} className="img-fluid blur-up lazyload bg-img" alt="" /></a>
                                    </div>
                                    <div className="cart-info cart-wrap">
                                        <button data-toggle="modal" data-target="#addtocart" title="Add to cart"><i className="ti-shopping-cart" ></i></button> <a href="javascript:void(0)" title="Add to Wishlist"><i className="ti-heart" aria-hidden="true"></i></a> <a href="#" data-toggle="modal" data-target="#quick-view" title="Quick View"><i className="ti-search" aria-hidden="true"></i></a> <a href="compare.html" title="Compare"><i className="ti-reload" aria-hidden="true"></i></a></div>
                                </div>
                                <div className="product-detail">
                                    <div className="rating"><i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i></div><a href="product-page(no-sidebar).html"><h6>Slim Fit Cotton Shirt</h6></a>
                                    <h4>$500.00</h4>
                                    <ul className="color-variant">
                                        <li className="bg-light0"></li>
                                        <li className="bg-light1"></li>
                                        <li className="bg-light2"></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-md-4 col-sm-6">
                            <div className="product-box">
                                <div className="img-wrapper">
                                    <div className="front">
                                        <a href="#"><img src={require("../assets/images/pro3/1.jpg")} className="img-fluid blur-up lazyload bg-img" alt="" /></a>
                                    </div>
                                    <div className="back">
                                        <a href="#"><img src={require("../assets/images/pro3/2.jpg")} className="img-fluid blur-up lazyload bg-img" alt="" /></a>
                                    </div>
                                    <div className="cart-info cart-wrap">
                                        <button data-toggle="modal" data-target="#addtocart" title="Add to cart"><i className="ti-shopping-cart" ></i></button> <a href="javascript:void(0)" title="Add to Wishlist"><i className="ti-heart" aria-hidden="true"></i></a> <a href="#" data-toggle="modal" data-target="#quick-view" title="Quick View"><i className="ti-search" aria-hidden="true"></i></a> <a href="compare.html" title="Compare"><i className="ti-reload" aria-hidden="true"></i></a></div>
                                </div>
                                <div className="product-detail">
                                    <div className="rating"><i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i></div><a href="product-page(no-sidebar).html"><h6>Slim Fit Cotton Shirt</h6></a>
                                    <h4>$500.00</h4>
                                    <ul className="color-variant">
                                        <li className="bg-light0"></li>
                                        <li className="bg-light1"></li>
                                        <li className="bg-light2"></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-md-4 col-sm-6">
                            <div className="product-box">
                                <div className="img-wrapper">
                                    <div className="front">
                                        <a href="#"><img src={require("../assets/images/pro3/27.jpg")} className="img-fluid blur-up lazyload bg-img" alt="" /></a>
                                    </div>
                                    <div className="back">
                                        <a href="#"><img src={require("../assets/images/pro3/28.jpg")} className="img-fluid blur-up lazyload bg-img" alt="" /></a>
                                    </div>
                                    <div className="cart-info cart-wrap">
                                        <button data-toggle="modal" data-target="#addtocart" title="Add to cart"><i className="ti-shopping-cart" ></i></button> <a href="javascript:void(0)" title="Add to Wishlist"><i className="ti-heart" aria-hidden="true"></i></a> <a href="#" data-toggle="modal" data-target="#quick-view" title="Quick View"><i className="ti-search" aria-hidden="true"></i></a> <a href="compare.html" title="Compare"><i className="ti-reload" aria-hidden="true"></i></a></div>
                                </div>
                                <div className="product-detail">
                                    <div className="rating"><i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i></div><a href="product-page(no-sidebar).html"><h6>Slim Fit Cotton Shirt</h6></a>
                                    <h4>$500.00</h4>
                                    <ul className="color-variant">
                                        <li className="bg-light0"></li>
                                        <li className="bg-light1"></li>
                                        <li className="bg-light2"></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-md-4 col-sm-6">
                            <div className="product-box">
                                <div className="img-wrapper">
                                    <div className="front">
                                        <a href="#"><img src={require("../assets/images/pro3/35.jpg")} className="img-fluid blur-up lazyload bg-img" alt="" /></a>
                                    </div>
                                    <div className="back">
                                        <a href="#"><img src={require("../assets/images/pro3/36.jpg")} className="img-fluid blur-up lazyload bg-img" alt="" /></a>
                                    </div>
                                    <div className="cart-info cart-wrap">
                                        <button data-toggle="modal" data-target="#addtocart" title="Add to cart"><i className="ti-shopping-cart" ></i></button> <a href="javascript:void(0)" title="Add to Wishlist"><i className="ti-heart" aria-hidden="true"></i></a> <a href="#" data-toggle="modal" data-target="#quick-view" title="Quick View"><i className="ti-search" aria-hidden="true"></i></a> <a href="compare.html" title="Compare"><i className="ti-reload" aria-hidden="true"></i></a></div>
                                </div>
                                <div className="product-detail">
                                    <div className="rating"><i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i></div><a href="product-page(no-sidebar).html"><h6>Slim Fit Cotton Shirt</h6></a>
                                    <h4>$500.00</h4>
                                    <ul className="color-variant">
                                        <li className="bg-light0"></li>
                                        <li className="bg-light1"></li>
                                        <li className="bg-light2"></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-md-4 col-sm-6">
                            <div className="product-box">
                                <div className="img-wrapper">
                                    <div className="front">
                                        <a href="#"><img src={require("../assets/images/pro3/2.jpg")} className="img-fluid blur-up lazyload bg-img" alt="" /></a>
                                    </div>
                                    <div className="back">
                                        <a href="#"><img src={require("../assets/images/pro3/1.jpg")} className="img-fluid blur-up lazyload bg-img" alt="" /></a>
                                    </div>
                                    <div className="cart-info cart-wrap">
                                        <button data-toggle="modal" data-target="#addtocart" title="Add to cart"><i className="ti-shopping-cart" ></i></button> <a href="javascript:void(0)" title="Add to Wishlist"><i className="ti-heart" aria-hidden="true"></i></a> <a href="#" data-toggle="modal" data-target="#quick-view" title="Quick View"><i className="ti-search" aria-hidden="true"></i></a> <a href="compare.html" title="Compare"><i className="ti-reload" aria-hidden="true"></i></a></div>
                                </div>
                                <div className="product-detail">
                                    <div className="rating"><i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i></div><a href="product-page(no-sidebar).html"><h6>Slim Fit Cotton Shirt</h6></a>
                                    <h4>$500.00</h4>
                                    <ul className="color-variant">
                                        <li className="bg-light0"></li>
                                        <li className="bg-light1"></li>
                                        <li className="bg-light2"></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-md-4 col-sm-6">
                            <div className="product-box">
                                <div className="img-wrapper">
                                    <div className="front">
                                        <a href="#"><img src={require("../assets/images/pro3/28.jpg")} className="img-fluid blur-up lazyload bg-img" alt="" /></a>
                                    </div>
                                    <div className="back">
                                        <a href="#"><img src={require("../assets/images/pro3/27.jpg")} className="img-fluid blur-up lazyload bg-img" alt="" /></a>
                                    </div>
                                    <div className="cart-info cart-wrap">
                                        <button data-toggle="modal" data-target="#addtocart" title="Add to cart"><i className="ti-shopping-cart" ></i></button> <a href="javascript:void(0)" title="Add to Wishlist"><i className="ti-heart" aria-hidden="true"></i></a> <a href="#" data-toggle="modal" data-target="#quick-view" title="Quick View"><i className="ti-search" aria-hidden="true"></i></a> <a href="compare.html" title="Compare"><i className="ti-reload" aria-hidden="true"></i></a></div>
                                </div>
                                <div className="product-detail">
                                    <div className="rating"><i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i></div><a href="product-page(no-sidebar).html"><h6>Slim Fit Cotton Shirt</h6></a>
                                    <h4>$500.00</h4>
                                    <ul className="color-variant">
                                        <li className="bg-light0"></li>
                                        <li className="bg-light1"></li>
                                        <li className="bg-light2"></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
      {/* <!-- product section end --> */}

      {/* Start Structured Data */}
      <div>
        <div itemtype="http://schema.org/Product" itemscope="">
          {/* <meta itemprop="mpn" content="925872" /> */}
          <meta itemprop="name" content={product.title} />
          <link itemprop="image" href={product.images[0].originalSrc} />
          <meta
            itemprop="description"
            content={`${product.title} is available in the ${category1} at ThrowThings.com for ${product.priceRangeV2.maxVariantPrice.amount}`}
          />
          <div
            itemprop="offers"
            itemtype="https://schema.org/Offer"
            itemscope=""
          >
            {/* <link itemprop="url" href="https://example.com/anvil" /> */}
            <meta itemprop="availability" content={availability} />
            <meta itemprop="priceCurrency" content="USD" />
            <meta
              itemprop="itemCondition"
              content="https://schema.org/NewCondition"
            />
            <meta
              itemprop="price"
              content={product.priceRangeV2.maxVariantPrice.amount}
            />
            <div
              itemprop="seller"
              itemtype="http://schema.org/Organization"
              itemscope=""
            >
              <meta itemprop="name" content="ThrowThings.com" />
            </div>
          </div>
          <meta itemprop="sku" content={product.variants[0].sku} />
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`query ($handle: String!) {
  shopifyProduct(handle: {eq: $handle}) {
    title
    images {
      gatsbyImageData(placeholder: TRACED_SVG, width: 910, layout: CONSTRAINED)
      id
      originalSrc
    }
    shopifyId
    handle
    description
    descriptionHtml
    priceRangeV2 {
      maxVariantPrice {
        amount
      }
      minVariantPrice {
        amount
      }
    }
    productType
    variants {
      sku
      weight
      availableForSale
    }
  }
}
`

export default ProductTemplate

//Deleted availableForSale after descriptionHtml 10/06/2021

//Deleted 
//localFile {
//  childImageSharp {
//    gatsbyImageData(width: 910, placeholder: TRACED_SVG, layout: CONSTRAINED)
//  }
//}
//after images originalSrc 10/06/2021